import { createTheme, Theme } from "@mui/material/styles";
import { deDE } from "@mui/material/locale";

export const useTheme = (): Theme => {
  const theme = createTheme(
    {
      palette: {
        primary: {
          light: "#f9f9f9",
          main: "rgb(0, 141, 211)",
          dark: "#696969",
        },
        error: {
          main: "#d32f2f",
        },
        success: {
          main: "#2e7d32",
        },
      },
    },
    deDE
  );

  theme.typography.h1 = {
    fontSize: "25px",
    fontFamily: `"Myriad Pro", sans-serif`,
    fontWeight: 300,
    lineHeight: 1.334,
    letterSpacing: "0em",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  };

  theme.typography.h2 = {
    fontSize: "1.25rem",
    fontFamily: `"Myriad Pro", sans-serif`,
    fontWeight: 300,
    lineHeight: 1.334,
    letterSpacing: "0em",
    color: theme.palette.primary.main,
  };

  theme.typography.h3 = {
    fontSize: "1.1rem",
    fontFamily: `"Myriad Pro", sans-serif`,
    fontWeight: 600,
    lineHeight: 1.334,
    letterSpacing: "0em",
    color: theme.palette.primary.dark,
  };

  theme.typography.h4 = {
    fontSize: "0.8rem",
    fontFamily: `"Myriad Pro", sans-serif`,
    fontWeight: 600,
    lineHeight: 1.334,
    letterSpacing: "0em",
    color: theme.palette.primary.dark,
  };

  // theme.typography.h3 = {
  //   fontFamily: `"Myriad Pro",sans-serif`,
  //   fontWeight: 400,
  //   fontSize: "1.1rem",
  //   lineHeight: 1.4,
  // };

  theme.typography.body1 = {
    fontFamily: `"Myriad Pro",sans-serif`,
    fontWeight: 300,
    fontSize: "0.875rem",
    lineHeight: 1.43,
  };

  theme.typography.body2 = {
    fontFamily: `"Myriad Pro",sans-serif`,
    fontWeight: 300,
    fontSize: "0.9rem",
    lineHeight: 1.35,
    // color: "#5a5a5a",
    color: "#535353",
  };
  theme.typography.subtitle1 = {
    fontFamily: `"Myriad Pro",sans-serif`,
    fontWeight: 300,
    fontSize: "0.9rem",
    lineHeight: 1.35,
    // color: "#5a5a5a",
    color: "#535353",
  };

  return theme;
};
