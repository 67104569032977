import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { CandidateT, updateCandidateBulk } from "../candidate/api";
import { Dialog } from "../shared/Dialog";
import { ErrorContext } from "../error/ErrorContext";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { ProfessionSelect } from "../profession/ProfessionSelect";
import { ProfessionContext } from "../profession/ProfessionContext";
import { Box } from "@mui/material";
import { WarnDialog } from "../shared/WarnDialog";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { ConfirmDialog } from "../shared/ConfirmDialog";

interface SetCandidateProfessionPropsI {
  candidates: CandidateT[];
  setCandidateList: React.Dispatch<React.SetStateAction<CandidateT[]>>;
  onChange: (i: number) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCandidateState?: React.Dispatch<React.SetStateAction<CandidateT>>;
}

export const SetCandidateProfession = ({
  candidates,
  setCandidateList,
  setCandidateState,
  onChange,
  open,
  setOpen,
}: SetCandidateProfessionPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar } = useContext(FeedbackContext);

  const [profession, setProfession] = useState<number>(
    candidates.length === 1 ? candidates[0].professionId : 0
  );
  const { professionList, setProfessionList } = useContext(ProfessionContext);

  /** must be set by effect when opening 2nd time */
  useEffect(() => {
    setProfession(candidates.length === 1 ? candidates[0].professionId : 0);
  }, [candidates]);

  const [showExpiredWarning, setShowExpiredWarning] = useState<boolean>(false);
  const [showTransactionFinished, setShowTransactionFinished] =
    useState<boolean>(false);

  const onClick = (ignoreTransactionFinished = false) => {
    const oldProfession =
      candidates.length === 1 ? candidates[0].professionId : 0;

    // check if profession is expired
    const prof = professionList.find((p) => p.id === profession);
    console.log("professionList", professionList);
    console.log("profession", profession);
    console.log("prof", prof);
    console.log("candidates", candidates.length);
    console.log("ignoreTransactionFinished:", ignoreTransactionFinished);
    if (prof) {
      if (prof.numberTransactions && prof.numberCandidates !== undefined)
        console.log(
          "prof.numberCandidates",
          prof.numberTransactions - prof.numberCandidates,
          prof.numberTransactions && prof.numberCandidates != undefined,
          prof.numberTransactions &&
            prof.numberCandidates != undefined &&
            prof.numberTransactions - prof.numberCandidates < candidates.length
        );
      if (prof.expiredAt && new Date() > new Date(prof.expiredAt)) {
        setShowExpiredWarning(true);
        return;
      }
      if (
        !ignoreTransactionFinished &&
        prof.numberTransactions &&
        prof.numberCandidates != undefined &&
        prof.numberTransactions - prof.numberCandidates < candidates.length
      ) {
        setShowTransactionFinished(true);
        return;
      }
    }

    updateCandidateBulk(
      candidates.map((c) => ({ ...c, professionId: profession }))
    )
      .then((res) => {
        if (res.success) {
          console.log("🌽 res", res);
          onChange(profession);
          openSnackbar("success", "Eintrag gespeichert");

          const updatedIds = res.data;
          if (updatedIds && typeof updatedIds === "object") {
            let prof: any = undefined;
            if (profession) {
              prof = professionList.find((p) => p.id === profession);

              setProfessionList((p) =>
                p.map((pro) => {
                  console.log("pro.id", pro);
                  return pro.id === profession &&
                    pro.numberCandidates !== undefined
                    ? {
                        ...pro,
                        numberCandidates:
                          pro.numberCandidates + updatedIds.length,
                      }
                    : pro;
                })
              );
            }
            console.log(
              "%cSetCandidateProfession.tsx line:58 prof",
              "color: orange;",
              prof
            );
            setCandidateList((p) =>
              p.map((c) => {
                if (updatedIds.indexOf(c.id) < 0) {
                  return c;
                }
                const cand = candidates.find(
                  (candidate) => c.id === candidate.id
                );
                return cand
                  ? {
                      ...c,
                      professionId: profession,
                      professionName: prof ? prof.name : undefined,
                      professionExpiredAt: prof ? prof.expiredAt : undefined,
                    }
                  : c;
              })
            );
          }
          if (setCandidateState)
            setCandidateState((c) => ({
              ...c,
              professionId: profession,
              professionExpiredAt: prof ? prof.expiredAt : undefined,
            }));

          if (oldProfession)
            setProfessionList((p) =>
              p.map((pro) =>
                pro.id === oldProfession && pro.numberCandidates !== undefined
                  ? {
                      ...pro,
                      numberCandidates: pro.numberCandidates - 1,
                    }
                  : pro
              )
            );
          setOpen(false);
        } else {
          setError(
            typeof res.error === "object" && "error" in res.error
              ? res.error["error"]
              : res.error
          );
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        setOpen={setOpen}
        title="Test zuordnen"
        showCancel={false}
      >
        <Box alignItems="center" sx={{ minWidth: 400 }}>
          <Grid item xs={12}>
            <ProfessionSelect
              id="profSelect"
              value={profession}
              setValue={setProfession}
              labelNoProfession="Kein Test zugeordnet"
            />

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button
                onClick={() => setOpen(false)}
                color="primary"
                variant="outlined"
              >
                Abbrechen
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onClick(false)}
              >
                Speichern
              </Button>
            </Box>
          </Grid>
        </Box>
      </Dialog>
      {showExpiredWarning && (
        <WarnDialog
          open={showExpiredWarning}
          handleOK={() => setShowExpiredWarning(false)}
          title="Bearbeitungsfrist abgelaufen"
          content="Die von Ihnen festgelegte Bearbeitungsfrist für diesen Test ist bereits abgelaufen. Bitte ändern Sie das Ablaufdatum (Dashboard > Übersicht „Meine Online-Tests“ > Spalte „Läuft bis“) oder wählen Sie einen anderen Test."
          textOK="OK"
        />
      )}
      {showTransactionFinished && (
        <ConfirmDialog
          open={showTransactionFinished}
          handleYes={() => {
            setShowTransactionFinished(false);
            onClick(true);
          }}
          handleNo={() => setShowTransactionFinished(false)}
          title="Nicht genug Lizenzen"
          content=" Sie haben mehr Teilnehmer ausgewählt, als Lizenzen für den
                  betreffenden Test verfügbar sind. Wählen Sie „Fortsetzen“, um
                  alle verfügbaren Lizenzen zuzuordnen; überzählige Teilnehmer
                  bleiben unversorgt."
          textYes="Fortsetzen"
          textNo="Zurück"
        />
      )}
    </>
  );
};
