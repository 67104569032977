import { addCandidate, CandidateT } from "../candidate/api";
import { useContext, useState } from "react";
import { ErrorContext } from "../error/ErrorContext";
import { CandidateForm } from "./CandidateForm";

import { Dialog } from "../shared/Dialog";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { CandidateContext } from "../candidate/CandidateContext";
import { addStateToArray } from "../shared/helper/state";
import { SplitButton } from "../shared/forms/SplitButton";
import { Box, Typography } from "@mui/material";
import { UploadCandidates } from "./UploadCandidates";
import { CloseModalButton } from "../shared/modal/CloseModalButton";
import { WarnDialog } from "../shared/WarnDialog";

interface AddCandidatePropsI {
  setFilter?: React.Dispatch<React.SetStateAction<string>>;
  setUploadedCandidateIds?: React.Dispatch<React.SetStateAction<number[]>>;
}
export const AddCandidate = ({
  setFilter,
  setUploadedCandidateIds,
}: AddCandidatePropsI) => {
  const { setCandidateList } = useContext(CandidateContext);
  const { setError } = useContext(ErrorContext);

  const [addCandidateOpen, setAddCandidateOpen] = useState(false);
  const [uploadCandidateOpen, setUploadCandidateOpen] = useState(false);
  const { openSnackbar } = useContext(FeedbackContext);

  const [uploadDialogWidth, setUploadDialogWidth] = useState("720px");
  const [isTransactionsFinished, setIsTransactionsFinished] =
    useState<boolean>(false);
  const [isTestAccountTooMany, setIsTestAccountTooMany] =
    useState<boolean>(false);

  const onClick = (data: CandidateT) => {
    if (!data.email) return;
    console.log("%cAddCandidate.tsx line:25 data", "color: #007acc;", data);
    addCandidate(data)
      .then((res) => {
        if (res.success && res.data) {
          setCandidateList((p) =>
            addStateToArray<CandidateT>(p, res.data as CandidateT)
          );
          setFilter && setFilter(data.email);
          openSnackbar("success", "Eintrag gespeichert");
          setAddCandidateOpen(false);
        } else {
          if (res.errors) {
            setError(res.error);
          }
        }
      })
      .catch((error) => {
        // console.log("hook catch", error);
        setError(error);
      });
  };

  const options = [
    {
      type: "add",
      text: "Teilnehmer anlegen",
    },
    {
      type: "import",
      text: "Teilnehmer importieren",
    },
  ];
  return (
    <Box
      display="flex"
      justifyContent="center"
      justifyItems="center"
      flexDirection="column"
      sx={{ height: "100%" }}
    >
      <SplitButton
        onClick={(type) => {
          if (type === "add") setAddCandidateOpen(true);
          else {
            setUploadCandidateOpen(true);
          }
        }}
        options={options}
      />
      <Dialog
        open={addCandidateOpen}
        setOpen={setAddCandidateOpen}
        showCancel={false}
        title="Neue/r Teilnehmer/in"
      >
        <CandidateForm
          onClick={onClick}
          button="Speichern"
          setOpen={setAddCandidateOpen}
        />
      </Dialog>
      <Dialog
        open={uploadCandidateOpen}
        setOpen={setUploadCandidateOpen}
        showCancel={false}
        title={
          <Typography variant="h1" component="h1" sx={{ pl: 1, pb: 2 }}>
            Teilnehmer/innen importieren
          </Typography>
        }
        width={uploadDialogWidth}
      >
        <>
          <CloseModalButton close={() => setUploadCandidateOpen(false)} />
          {setFilter && setUploadedCandidateIds ? (
            <UploadCandidates
              onClick={onClick}
              button="Speichern"
              setOpen={setUploadCandidateOpen}
              setFilter={setFilter}
              setUploadedCandidateIds={setUploadedCandidateIds}
              setIsTestAccountTooMany={setIsTestAccountTooMany}
              setIsTransactionsFinished={setIsTransactionsFinished}
            />
          ) : (
            <></>
          )}
        </>
      </Dialog>
      {isTransactionsFinished && (
        <WarnDialog
          open={isTransactionsFinished}
          handleOK={() => {
            setIsTransactionsFinished(false);
          }}
          title="Teilnehmer importieren"
          content="Sie haben mehr Teilnehmer importiert, als Lizenzen für den gewählten
      Beruf verfügbar sind. Einigen Teilnehmern konnte daher kein Test
      zugeordnet werden."
        />
      )}
      {isTestAccountTooMany && (
        <WarnDialog
          open={isTestAccountTooMany}
          handleOK={() => setIsTestAccountTooMany(false)}
          title="Teilnehmer importieren"
          content="In Ihrem kostenlosen Probekonto ist die Import-Funktion auf maximal 10
          Teilnehmer beschränkt."
        />
      )}
    </Box>
  );
};
