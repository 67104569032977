import { useCallback, useContext, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import { CandidateContext } from "../candidate/CandidateContext";

import { FeedbackContext } from "../feedback/FeedbackContext";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  Dialog,
  DialogActions,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CandidateT, checkUploadCandidates, uploadCandidates } from "./api";
import { ErrorContext } from "../error/ErrorContext";
import { useDropzone } from "react-dropzone";
import { humanFileSize } from "../shared/helper/file";
import { FileT } from "../file/api";
import { date_hr } from "../shared/helper/datetime";
import { ProfessionContext } from "../profession/ProfessionContext";
import { ProfessionSelect } from "../profession/ProfessionSelect";
import { addStateToArray } from "../shared/helper/state";
import React from "react";
import { ConfirmDialog } from "../shared/ConfirmDialog";
import { WarnDialog } from "../shared/WarnDialog";

const filesize = parseInt(import.meta.env.VITE_FILESIZE || "4");

interface CandidateFormPropsI {
  candidate?: CandidateT;
  onClick: (data: CandidateT) => void;
  button: string;
  edit?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadedCandidateIds: React.Dispatch<React.SetStateAction<number[]>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setIsTestAccountTooMany: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTransactionsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

const EMPTYUSER: CandidateT = {
  id: 0,
  customerId: 0,
  professionId: 0,
  firstname: "",
  lastname: "",
  email: "",
  street: "",
  zipcode: "",
  city: "",
  country: "",
  phone: "",
  uuid: "",
  dateBirth: null,
  comment: "",
  createdAt: new Date(),
  schoolGraduation: "",
};

export const UploadCandidates = (props: CandidateFormPropsI) => {
  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress, hideProgress } =
    useContext(FeedbackContext);
  const [fileList] = useState<FileT[]>([]);
  const [isPreview, setIsPreview] = useState(false);
  const [previewData, setPreviewData] = useState<CandidateT[]>([]);
  const { setCandidateList } = useContext(CandidateContext);
  // errors from backend, are used to highlight fields in preview data
  const [checkErrors, setCheckErrors] = useState<string[][]>([] as string[][]);
  // type of error "empty", "gender", "date", "gender_date"
  const [errorType, setErrorType] = useState<string>("");

  const [errorEmail, setErrorEmail] = useState(false);
  // dups from backend, are used to highlight rows in preview
  const [checkIntraDups, setCheckIntraDups] = useState<string[]>([]);
  // how to handle dups
  const [intraDupStrategy, setIntraDupStrategy] = useState<string | null>(null);
  // dups from backend, are used to highlight rows in preview
  const [checkDups, setCheckDups] = useState<string[][]>([]);
  // how to handle dups
  const [dupStrategy, setDupStrategy] = useState<string | null>(null);
  // how to handle invalid data
  const [invalidStrategy, setInvalidStrategy] = useState<string | null>(null);

  const { professionList, setProfessionList } = useContext(ProfessionContext);

  const [fileError, setFileError] = useState<string | null>(null);

  if (props.candidate) {
    Object.entries(EMPTYUSER).forEach(([key, val]) => {
      if (props.candidate && !props.candidate[key as keyof CandidateT])
        (props.candidate[key as keyof CandidateT] as typeof val) = val;
    });
  }

  const [file, setFile] = useState<File>();
  const [filename, setFilename] = useState<string>("");
  const [selectedProfession, setSelectedProfession] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newfiles = acceptedFiles.filter((f: File) => {
        if (f.size > filesize * 1024000) {
          // TODO: [DEGEBA-118] central config
          openSnackbar(
            "info",
            "Die Dateigröße darf maximal " + filesize + " MB betragen"
          );
          return false;
        }
        return true;
      });

      setFile(newfiles[0]);
    },
    [openSnackbar, fileList]
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, accept: { "text/csv": [".csv"] }, multiple: false });

  useEffect(() => {
    console.log("intraDupStrategy:", intraDupStrategy);
    if (intraDupStrategy === "skip") {
      setPreviewData((p) => {
        const uniqueEmails = new Set();
        return p.filter((item) => {
          if (!uniqueEmails.has(item.email)) {
            uniqueEmails.add(item.email);
            return true;
          }
          return false;
        });
      });
    }
  }, [intraDupStrategy]);

  useEffect(() => {
    if (!acceptedFiles.length) setFile(undefined);
  }, [acceptedFiles]);

  const onClickCheck = () => {
    if (!file) return;
    setFileError(null);
    setErrorEmail(false);

    const formData = new FormData();

    showProgress();

    console.log("onClickUpload files");
    formData.append("file", file);
    console.log("formData", formData);
    checkUploadCandidates(formData)
      .then((result) => {
        hideProgress();

        console.log("uploadFile 🌈🌈 result", result);

        if (
          result.success &&
          result.data &&
          result.data.errors &&
          Object.keys(result.data.errors).length
        ) {
          console.log("1111 result.data.errors", result.data.errors);

          setCheckErrors(result.data.errors);

          const errorStr = JSON.stringify(result.data.errors);
          console.log("🍕🍕🍕 errorStr", errorStr);
          if (errorStr.includes("empty field")) setErrorType("empty");
          else if (errorStr.includes("invalid email")) {
            setErrorType("empty");
            setErrorEmail(true);
          } else {
            let hasGender = false;
            let hasDate = false;
            if (errorStr.includes("invalid gender")) {
              hasGender = true;
            }
            if (errorStr.includes("invalid date")) {
              hasDate = true;
            }
            if (hasGender && hasDate) setErrorType("gender_date");
            else if (hasGender) setErrorType("gender");
            else if (hasDate) setErrorType("date");
          }
        }

        if (
          result.success &&
          result.data &&
          result.data.dups &&
          Object.keys(result.data.dups).length
        ) {
          setCheckDups(result.data.dups);
        }
        if (
          result.success &&
          result.data &&
          result.data.intraDups &&
          Object.keys(result.data.intraDups).length
        ) {
          setCheckIntraDups(result.data.intraDups);
        }

        if (!result.success && result.error) {
          if (result.error.indexOf("is empty") >= 0) {
            setFileError(
              "Die Datei ist leer. Bitte nutzen Sie die oben abrufbare CSV-Dateivorlage und tragen Sie die Teilnehmerdaten ein."
            );
          } else if (result.error.indexOf("ndefined array") >= 0) {
            setFileError(
              "Die Datei kann nicht verarbeitet werden, da sie nicht die erwartete Anzahl an Spalten enthält. Bitte nutzen Sie die oben abrufbare CSV-Dateivorlage."
            );
            setFile(undefined);
          } else if (result.error.indexOf("no header") >= 0) {
            setFileError(
              "Die Datei kann nicht verarbeitet werden, da sie nicht die erwartete Kopfzeile enthält.  Bitte nutzen Sie die oben abrufbare CSV-Dateivorlage."
            );
            setFile(undefined);
          } else if (result.error.indexOf("only example") >= 0) {
            setFileError(
              "Die Datei kann nicht verarbeitet werden, da sie nur den Musterdatensatz enthält. Bitte tragen Sie die Teilnehmerdaten ein."
            );
            setFile(undefined);
          } else if (result.error.indexOf("no data") >= 0) {
            setFileError(
              "Die Datei kann nicht verarbeitet werden, da sie keine Daten enthält. Bitte nutzen Sie die oben abrufbare CSV-Dateivorlage und tragen Sie die Teilnehmerdaten ein."
            );
            setFile(undefined);
          } else if (result.error.indexOf("for test account") >= 0) {
            props.setIsTestAccountTooMany(true);
            setFile(undefined);
          } else setFileError(result.error);
        }
        if (result.data?.filename) {
          setIsPreview(true);
          setFilename(result.data.filename);

          if (result.data?.candidates) setPreviewData(result.data?.candidates);
        }
      })
      .catch((error) => {
        console.log("uploadFile catch", error);
        hideProgress();
        setError(error);
      });
  };

  const setBackAll = () => {
    setIsPreview(false);
    setDupStrategy(null);
    setIntraDupStrategy(null);
    setCheckIntraDups([]);
    setInvalidStrategy(null);
    setCheckErrors([]);
    setCheckDups([]);
    setErrorType("");
    setSelectedProfession(0);
    setFile(undefined);
    setErrorEmail(false);
  };

  const onClickUpload = (
    tmpDupStrategy: string | null,
    tmpIntraDupStrategy: string | null
  ) => {
    uploadCandidates(
      filename,
      selectedProfession,
      tmpDupStrategy ? tmpDupStrategy : dupStrategy,
      invalidStrategy,
      tmpIntraDupStrategy ? tmpIntraDupStrategy : intraDupStrategy
    ).then((result) => {
      hideProgress();
      console.log(
        "%csrc/components/candidate/UploadCandidates.tsx:172 result",
        "color: #007acc;",
        result
      );
      if (result.success) {
        if (result.data && result.data.transctionsFinished) {
          props.setIsTransactionsFinished(true);
          props.setOpen(false);
        } else {
          openSnackbar("success", "Teilnehmer importiert");
          props.setOpen(false);
        }
        result.data?.candidates.forEach((c) => {
          setCandidateList((p) => addStateToArray<CandidateT>(p, c));
        });

        props.setUploadedCandidateIds(
          result.data?.candidates.map((c) => c.id) || []
        );

        if (result.data?.professions)
          setProfessionList(result.data?.professions);

        setBackAll();
        props.setFilter("");
      } else {
        setError(result.error);
      }
    });
  };

  // !selectedProfession || professionList[selectedProfession].length > previewData.length
  console.log("selectedProfession", selectedProfession);
  console.log("professionList", professionList);
  console.log("previewData", previewData.length);

  const backButton = (
    <Button variant="contained" onClick={setBackAll} sx={{ mr: 2 }}>
      Zurück
    </Button>
  );

  const professionData = professionList.find(
    (p) => p.id === selectedProfession
  );

  console.log("professionData", professionData);
  console.log(
    "professionData.numberTransactions",
    professionData?.numberTransactions
  );
  console.log(
    "professionData.numberCandidates",
    professionData?.numberCandidates
  );

  const transactionAvailable =
    selectedProfession &&
    professionData &&
    professionData.numberTransactions &&
    professionData.numberCandidates != null
      ? professionData.numberTransactions - professionData.numberCandidates
      : 0;

  console.log("transactionAvailable", transactionAvailable);

  return (
    <Box>
      {isPreview ? (
        <Box className="candidate-upload">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Vorname</TableCell>
                  <TableCell align="left">Nachname</TableCell>
                  <TableCell align="left">Anrede</TableCell>
                  <TableCell align="left">E-Mail</TableCell>
                  <TableCell align="left">Geburtsdatum</TableCell>
                  <TableCell align="left">Straße</TableCell>
                  <TableCell align="left">Hausnr.</TableCell>
                  <TableCell align="left">PLZ</TableCell>
                  <TableCell align="left">Ort</TableCell>
                  <TableCell align="left">Telefon</TableCell>
                  <TableCell align="left">Staatsangehörigkeit</TableCell>
                  <TableCell align="left">Schulabschluss</TableCell>
                  <TableCell align="left">Bemerkungen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData.map(
                  (row, idx) =>
                    (dupStrategy != "skip" ||
                      !(
                        Object.keys(checkDups).length &&
                        idx in checkDups &&
                        checkDups[idx] &&
                        3 in checkDups[idx] &&
                        checkDups[idx][3].includes("already exists")
                      )) && (
                      <TableRow
                        key={row.firstname + idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={
                            checkErrors &&
                            idx in checkErrors &&
                            checkErrors[idx] &&
                            0 in checkErrors[idx]
                              ? "upload-field-error"
                              : ""
                          }
                        >
                          {row.firstname}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={
                            checkErrors &&
                            idx in checkErrors &&
                            checkErrors[idx] &&
                            1 in checkErrors[idx]
                              ? "upload-field-error"
                              : ""
                          }
                        >
                          {row.lastname}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            !invalidStrategy &&
                            checkErrors &&
                            errorType !== "empty" &&
                            idx in checkErrors &&
                            checkErrors[idx] &&
                            2 in checkErrors[idx]
                              ? "upload-field-error"
                              : ""
                          }
                        >
                          {row.gender === "m"
                            ? "Herr"
                            : row.gender === "f"
                            ? "Frau"
                            : row.gender === "d"
                            ? "Divers"
                            : invalidStrategy
                            ? ""
                            : row.gender}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            checkErrors &&
                            idx in checkErrors &&
                            checkErrors[idx] &&
                            3 in checkErrors[idx]
                              ? "upload-field-error"
                              : ""
                          }
                        >
                          {row.email}
                          <Box sx={{ color: "red" }}>
                            {((invalidStrategy && errorType !== "empty") ||
                              errorType == "") &&
                              (Object.keys(checkIntraDups).length &&
                              checkIntraDups.find((p) => p === row.email) &&
                              !intraDupStrategy
                                ? " - E-Mail ist mehrfach vorhanden"
                                : Object.keys(checkDups).length &&
                                  idx in checkDups &&
                                  checkDups[idx] &&
                                  3 in checkDups[idx] &&
                                  checkDups[idx][3].includes("already exists")
                                ? " - E-Mail existiert bereits"
                                : "")}
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            !invalidStrategy &&
                            checkErrors &&
                            idx in checkErrors &&
                            errorType !== "empty" &&
                            checkErrors[idx] &&
                            4 in checkErrors[idx]
                              ? "upload-field-error"
                              : ""
                          }
                        >
                          {checkErrors &&
                          idx in checkErrors &&
                          checkErrors[idx] &&
                          4 in checkErrors[idx]
                            ? invalidStrategy
                              ? ""
                              : checkErrors[idx][4].replace("invalid date:", "")
                            : ""}
                          {row.dateBirth ? date_hr(row.dateBirth) : ""}
                        </TableCell>
                        <TableCell align="left">{row.street}</TableCell>
                        <TableCell align="left">{row.streetno}</TableCell>
                        <TableCell align="left">{row.zipcode}</TableCell>
                        <TableCell align="left">{row.city}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.nationality}</TableCell>
                        <TableCell align="left">
                          {row.schoolGraduation}
                        </TableCell>
                        <TableCell align="left">{row.comment}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* B U T T O N S  */}
          {errorType === "empty" && !errorEmail && (
            <Box sx={{ mt: 3 }}>
              Die Datei kann nicht importiert werden, da Pflichtangaben fehlen
              (Name, Vorname, E-Mail-Adresse). Bitte vervollständigen Sie die
              Angaben oder entfernen Sie die betreffenden Datensätze.
              <Box sx={{ mt: 3 }}>{backButton}</Box>
            </Box>
          )}
          {errorEmail && (
            <Box>
              <br />
              Eine oder mehrere E-Mail-Adressen sind ungültig. Bitte korrigieren
              Sie die markierten Felder.
            </Box>
          )}
          {/* We have an error and no strategy how to handle invalid-data */}
          {!invalidStrategy && errorType && errorType !== "empty" ? (
            <Box sx={{ mt: 3 }}>
              Bitte prüfen Sie die markierten Felder:
              {errorType.includes("date") && (
                <Box>
                  Geben Sie das Geburtsdatum im Format TT.MM.JJJJ an (z.B.
                  01.05.2009).
                </Box>
              )}
              {errorType.includes("gender") && (
                <Box>
                  Erlaubte Angaben für Anrede sind „Herr“ – „Frau“ – „Divers“
                  oder keine Angabe.
                </Box>
              )}
              <Box sx={{ mt: 3 }}>
                {backButton}
                <Button
                  variant="contained"
                  onClick={() => {
                    setInvalidStrategy("ignore");
                  }}
                >
                  Ignorieren
                </Button>
              </Box>
            </Box>
          ) : // End invalidFields
          // I N T R A - D U P L I C A T E  - Handling
          //  We have dups and no strategy how to handle dups
          errorType !== "empty" &&
            Object.keys(checkIntraDups).length > 0 &&
            !intraDupStrategy ? (
            <>
              <Box sx={{ mt: 2 }}>
                Eine oder mehrere E-Mail-Adressen kommen in der CSV-Datei
                mehrfach vor. Möchten Sie die betreffenden Teilnehmer
                überspringen oder jeweils einen weiteren Eintrag erstellen?
              </Box>
              <Box sx={{ mt: 3 }}>
                {backButton}
                <Button
                  variant="contained"
                  onClick={() => {
                    setIntraDupStrategy("skip");
                  }}
                  sx={{ mr: 2 }}
                >
                  Duplikate überspringen
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (Object.keys(checkDups).length == 0) {
                      onClickUpload("", "add");
                    } else {
                      setIntraDupStrategy("add");
                    }
                  }}
                >
                  Weitere Einträge erstellen
                </Button>
              </Box>
            </>
          ) : // D U P L I C A T E  - Handling
          //  We have dups and no strategy how to handle dups
          errorType !== "empty" &&
            Object.keys(checkDups).length > 0 &&
            !dupStrategy ? (
            <>
              <Box sx={{ mt: 2 }}>
                Eine oder mehrere E-Mail-Adressen existieren bereits. Möchten
                Sie die betreffenden Teilnehmer überspringen oder jeweils einen
                weiteren Eintrag erstellen?
              </Box>
              <Box sx={{ mt: 2 }}>
                {backButton}
                <Button
                  variant="contained"
                  onClick={() => {
                    setDupStrategy("skip");
                  }}
                  sx={{ mr: 3 }}
                >
                  Duplikate überspringen
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (
                      !selectedProfession ||
                      transactionAvailable >= previewData.length
                    ) {
                      onClickUpload("add", null);
                    } else {
                      setDupStrategy("add");
                    }
                  }}
                >
                  Weitere Einträge erstellen
                </Button>
              </Box>
            </>
          ) : (
            // End Dups
            errorType !== "empty" &&
            // this is the check if we have something left, when skipped
            (dupStrategy === "add" ||
              (dupStrategy === "skip" &&
                Object.keys(checkDups).length < previewData.length) ||
              Object.keys(checkDups).length == 0) &&
            // no dups at all
            // no prof or enough transactions
            (!selectedProfession ||
            transactionAvailable >= previewData.length ? (
              <Box sx={{ mt: 3 }}>
                {backButton}
                <Button
                  variant="contained"
                  onClick={() => onClickUpload(null, null)}
                >
                  Speichern
                </Button>
              </Box>
            ) : (
              <>
                <Box sx={{ mt: 3 }}>
                  Sie haben mehr Teilnehmer ausgewählt, als Lizenzen für den
                  betreffenden Test verfügbar sind. Wählen Sie „Fortsetzen“, um
                  alle verfügbaren Lizenzen zuzuordnen; überzählige Teilnehmer
                  bleiben unversorgt.
                  <Box sx={{ mt: 3 }}>
                    {backButton}
                    <Button
                      variant="contained"
                      onClick={() => onClickUpload(null, null)}
                    >
                      Fortsetzen
                    </Button>
                  </Box>
                </Box>
              </>
            ))
          )}

          {dupStrategy === "skip" && checkDups.length > 0 && (
            <Box>
              <br />
              Doppelte Datensätze wurden übersprungen – es sind keine weiteren
              Teilnehmer ausgewählt.
            </Box>
          )}
        </Box>
      ) : (
        <Box className="candidate-upload">
          <Grid container spacing="2">
            <Grid xs={12} item>
              Importieren Sie Teilnehmerdaten aus einer CSV-Datei. Bitte nutzen
              Sie dafür folgende Dateivorlage:
            </Grid>
            <Grid item>
              <a href="/csv/Teilnehmer-importieren.csv" download>
                <FileDownloadIcon />{" "}
              </a>
            </Grid>
            <Grid item>
              <a href="/csv/Teilnehmer-importieren.csv" download>
                Teilnehmer-importieren.csv
              </a>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <ul>
                <li>
                  Die Pflichtangaben Name, Vorname und E-Mail-Adresse müssen für
                  jeden Teilnehmer vorhanden sein.
                </li>
                <li>
                  Das Geburtsdatum muss dem Format TT.MM.JJJJ entsprechen.
                </li>
                <li>
                  Als Anrede können Sie „Herr“, „Frau“, oder „Divers“ angeben.
                </li>
              </ul>
            </Grid>
            <Grid item {...getRootProps()} xs={6} sx={{ mb: 2 }}>
              {file ? (
                <Box
                  sx={{
                    border: "1px solid gray",
                    background: isDragActive ? "gray" : "#f5f5f5",
                    minHeight: 40,
                    p: 2,
                    mb: 2,
                    paddingRight: "20px",
                    position: "relative",
                    width: 400,
                  }}
                >
                  <Typography variant="body2" key="file">
                    {file.name} - {humanFileSize(file.size)}
                  </Typography>
                  <input {...getInputProps()} />

                  <CloseIcon
                    sx={{
                      fontSize: 12,
                      color: "#535353",
                      position: "absolute",
                      right: 5,
                      top: 5,
                    }}
                  ></CloseIcon>
                </Box>
              ) : (
                <Button
                  sx={{
                    background: isDragActive ? "darkgrey" : "#f5f5f5",
                    border: "1px solid gray",
                    height: 38,
                    verticalAlign: "middle",
                    width: 400,
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? "Datei hier Droppen" : "Datei wählen"}
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" component="div" sx={{ fontSize: "16px" }}>
              Test wählen (optional)
            </Typography>
            Ordnen Sie den Teilnehmern einen Test zu. Sie können die Zuordnung
            auch später vornehmen. Um schnell verschiedene Testgruppen
            anzulegen, können Sie jede Gruppe in einer eigenen CSV-Datei
            importieren.
            <br />
            <FormControl sx={{ mt: 1, mb: 0, minWidth: 240 }}>
              <ProfessionSelect
                id="profSelect"
                value={selectedProfession}
                setValue={setSelectedProfession}
                labelNoProfession="-"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {fileError && <Box sx={{ color: "red" }}>{fileError}</Box>}
          </Grid>

          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={6}>
              {acceptedFiles.length > 0 && file ? (
                <Button variant="contained" onClick={onClickCheck}>
                  Upload
                </Button>
              ) : (
                <Button variant="contained" disabled>
                  Upload
                </Button>
              )}
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Button variant="outlined" onClick={() => props.setOpen(false)}>
                Abbrechen
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
