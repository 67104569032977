/**
 * generic timer hook
 *
 * timer always cause up, if need backward, must be done outside
 *
 * @param endTime: int|optional: time when time is ended and endFunc() is called
 * @param endFunc: callback when timer is finished
 * @param name: string: distinct between multiple times
 *
 * @returns time: actual time as number
 * @returns clearTimer: func to be called outsied to clear timer
 * @returns startTimer: func to be called outsied to start timer
 * @returns restartTimer: func to be called outsied to restart timer
 *
 */
import { useContext, useEffect, useState } from "react";
import { uglifyTimestamp, unUglifyTimestamp } from "../shared/helper/uglify";
import { LSKEYS, TestModusContext } from "./TestModusContext";
import { getUnixTimestamp } from "../shared/helper/datetime";

interface UseTimerOptionsI {
  endTime?: number;
  endFunc?: () => void;
  name?: string;
}
export const useTimer = (
  initialTime: number,
  { endTime = 999999999, endFunc, name = "" }: UseTimerOptionsI = {}
) => {
  const [time, setTime] = useState(initialTime);
  /** we store the interval to be later able to delete it */
  const [timeIntervalId, setTimeIntervalId] = useState(0);
  const { setInitialTime } = useContext(TestModusContext);

  /** we might have a callback when the time is up */
  useEffect(() => {
    if (endFunc && time === endTime) {
      endFunc();
    }
  }, [time, endTime, endFunc]);

  /**
   * clears timer, remove from local storage  and set time to -1
   */
  const clearTimer = () => {
    if (name) localStorage.removeItem(name);
    window.clearInterval(timeIntervalId);
    setTime(-1);
  };

  /** shortcut to clear + start */
  const restartTimer = () => {
    clearTimer();
    startTimer();
  };

  /**
   *
   * @param initialTimeOverwrite: might set initial time to a different value then now()
   *
   * stores initial time in local storage
   * startsInterval
   */
  const startTimer = (initialTimeOverwrite = 0) => {
    const initialTimeAct = initialTimeOverwrite || initialTime;
    console.log("%cuseTimer.ts line:66 startTimer", "color: #007acc;", name);
    if (initialTimeOverwrite && name in LSKEYS) {
      const k = Object.values(LSKEYS).find((k) => k === name);
      if (k) setInitialTime(k, initialTimeOverwrite);
    }
    if (endTime && initialTimeAct >= endTime) return;
    if (name && initialTimeAct <= 0)
      localStorage.setItem(name, uglifyTimestamp(getUnixTimestamp()));

    setTime(initialTimeAct);
    /** add 1 every second */
    const id = window.setInterval(() => {
      setTime((p) => {
        /** every 10 seconds we check for rounding differences / problems with milliseconds
         * and correct them regarding the time in local storage
         */
        if (p > 0 && p % 10 === 0 && name) {
          const timeAct = localStorage.getItem(name);
          if (timeAct) {
            let newTime = Math.round(
              getUnixTimestamp() - unUglifyTimestamp(timeAct)
            );

            return newTime;
          }
        }
        return p + 1;
      });
    }, 1000);
    setTimeIntervalId(id);
    if (name === LSKEYS.TIME_ACT) setInitialTime(LSKEYS.TIME_ACT, 0);
  };

  return {
    time,
    clearTimer,
    startTimer,
    restartTimer,
  };
};
