import { useContext, useEffect, useState } from "react";
import { QuestionContainer } from "./QuestionContainer";
import {
  LSKEYS,
  TestModusContext,
  removeTestModusLocalStore,
} from "./TestModusContext";
import { TestModusDashboard } from "./TestModusDashboard";
import { TestModusSubCategoryStart } from "./TestModusSubCategoryStart";
import { usePrompt } from "../shared/hooks/usePrompt";
import { PROMPT_WARN } from "../shared/const/const";
import { DebugToolBox } from "../DebugToolBox";
import { Loader } from "../shared/Loader";
import { AuthContext } from "../auth/AuthContext";
import { addResult } from "./api";
import {
  unUglifyNumber,
  unUglifyTimes,
  unUglifyTimestamp,
} from "../shared/helper/uglify";
import { getUnixTimestamp } from "../shared/helper/datetime";
import { ModalReloaded } from "../auth/ModalReloaded";
import { AutoLoginError } from "../auth/AutoLoginError";

export const TestModusStart = () => {
  const {
    started,
    setStarted,
    setFinished,
    finished,
    questionNo,
    setQuestionNo,
    setCandidateAnswers,
    setInitialTime,
    actMainCategory,
    actSubCategory,
    setActSubCategory,
    questionList,
    setActMainCategory,
    setCandidateSubCategoryTime,
    startTimerTotal,
  } = useContext(TestModusContext);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    setInterval(() => {
      if (window.location.pathname === "/candidate") {
        if (localStorage.getItem(LSKEYS.FINISHED)) {
          window.location.href = "/candidate";
        }
      }
    }, 1000);
  });

  /** we need to make sure, that reload-check is done before we render anything else */
  const [reloadCheckDone, setReloadCheckDone] = useState(false);
  const [reloadDone, setReloadDone] = useState(false);
  /**
   * reload? read details from local-storage
           _                 _ 
          | |               | |
  _ __ ___| | ___   __ _  __| |
 | '__/ _ \ |/ _ \ / _` |/ _` |
 | | |  __/ | (_) | (_| | (_| |
 |_|  \___|_|\___/ \__,_|\__,_|
                               
   *
   * */
  useEffect(() => {
    if (started) {
      setReloadCheckDone(true);
      return;
    }
    console.log(
      "%cTestModusStart.tsx line:52 RELOAD CHECK",
      "color: red;",
      started
    );

    let qno = unUglifyNumber(localStorage.getItem(LSKEYS.QUESTION_NO) || "");
    if (qno >= 0) {
      setReloadDone(true);
      console.log(
        "%cTestModusStart.tsx line:66 question from localStore: ",
        "color: gold",
        qno
      );
      /** reload while 5-sec-countdown ("Time is up") running? -> next subcat */
      if (localStorage.getItem(LSKEYS.COUNTDOWN_TIME)) {
        console.log(
          "%creload while 5-sec-countdown (Time is up) running! -> next subcat. current subcat:" +
            questionList[qno].subCategoryId,
          "color: gold;"
        );
        const s = questionList[qno].subCategoryId;
        /* search for first question with other subCategoryId */
        while (
          qno < questionList.length &&
          s === questionList[qno].subCategoryId
        )
          qno++;

        console.log(
          "%cTestModusStart.tsx line:85 new QuestionNo",
          "color: gold;",
          qno,
          qno in questionList
            ? questionList[qno].subCategoryId
            : " no question left"
        );
        localStorage.removeItem(LSKEYS.COUNTDOWN_TIME);

        /* we were on the very last question */
        if (qno === questionList.length) {
          /** is someone is so dump to reload in that moment
           * he deserves nothing, however we try to save
           */
          if (!authState.user.demo) {
            const tsubs = localStorage.getItem(LSKEYS.TIME_SUBS);
            addResult(
              questionList,
              unUglifyTimes(JSON.parse(tsubs || "{total:xxxxx0xxxxx}")),
              authState.user.professionId
            );
          }
          setFinished(true);
          removeTestModusLocalStore();
        }
      }
    }
    if (qno >= 0 && qno < questionList.length) {
      setQuestionNo(qno);

      /**
       *  TIME_ACT exists, so Test was already started
       *  -> we jump directly to the cats
       * otherwise we show overview!
       */
      const timeAct = localStorage.getItem(LSKEYS.TIME_ACT);
      const now = getUnixTimestamp();
      if (timeAct) {
        let newTime = now - unUglifyTimestamp(timeAct);
        console.log(
          "%cTestModusStart.tsx line:120 setInitialTime newTime",
          "color: red;",
          now,
          unUglifyTimestamp(timeAct),
          newTime
        );
        setInitialTime(LSKEYS.TIME_ACT, newTime);
      }
      const timeStart = localStorage.getItem(LSKEYS.TIME_START);
      if (timeStart) {
        let newTime = now - unUglifyTimestamp(timeStart);
        console.log(
          "%cTestModusStart.tsx line:120 setInitialTime newTime",
          "color: red;",
          now,
          unUglifyTimestamp(timeStart),
          newTime
        );
        setInitialTime(LSKEYS.TIME_START, newTime);
        startTimerTotal(newTime);
      }

      /**
       *  TIME_MEM exists, so we were on memory countdown
       */
      const timeMem = localStorage.getItem(LSKEYS.TIME_MEM);
      if (timeMem) {
        let newTime = now - unUglifyTimestamp(timeMem);
        console.log(
          "%c timeMemtimeMemtimeMem setInitialTime newTime",
          "color: red;",
          now,
          unUglifyTimestamp(timeMem),
          newTime
        );
        setInitialTime(LSKEYS.TIME_MEM, newTime);
      }
      const maincat = localStorage.getItem(LSKEYS.MAIN_CAT_ID);
      if (maincat) setActMainCategory(unUglifyNumber(maincat));

      const cas = localStorage.getItem(LSKEYS.CANDIDATE_ANSWERS);
      if (cas) setCandidateAnswers(JSON.parse(cas));

      const tsubs = localStorage.getItem(LSKEYS.TIME_SUBS);
      if (tsubs)
        setCandidateSubCategoryTime(
          unUglifyTimes(JSON.parse(tsubs || "{total:xxxxx0xxxxx}"))
        );

      const subcat = localStorage.getItem(LSKEYS.COUNTDOWN_MEM)
        ? questionList[qno].subCategoryId
        : unUglifyNumber(localStorage.getItem(LSKEYS.SUB_CAT_ID) || "");
      if (subcat) setActSubCategory(subcat);

      setStarted(true);
    }
    setReloadCheckDone(true);
  }, [
    started,
    setStarted,
    setQuestionNo,
    setCandidateAnswers,
    setInitialTime,
    questionList,
    setActMainCategory,
    setCandidateSubCategoryTime,
    setActSubCategory,
  ]);

  /** we warn the candidate to go away, customer might go away, also because there were some problems with unstable_usePrompt */
  usePrompt(
    PROMPT_WARN,
    started && !finished && !(authState.user.role === "customer")
  );

  if (!reloadCheckDone) return <Loader />;

  return (
    <>
      {reloadDone && <ModalReloaded setClose={() => setReloadDone(false)} />}
      {finished ? (
        <TestModusDashboard />
      ) : actMainCategory !== questionList[questionNo].mainCategoryId ? (
        <TestModusDashboard />
      ) : actSubCategory !== questionList[questionNo].subCategoryId ? (
        <TestModusSubCategoryStart />
      ) : (
        <QuestionContainer />
      )}
      <DebugToolBox key={"debug" + questionNo} />
    </>
  );
};
