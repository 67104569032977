import { useContext, useEffect, useState } from "react";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { getStoredAuthToken, removeStoredAuthToken } from "./localStore";
import { AuthContext, buildAuthState } from "./AuthContext";
import { getUserRole } from "./api";
import { redirectAfterLoggedout, setAutoLogout } from "../shared/helper/logout";

export const useAutoLogin = () => {
  const [autoLoginChecked, setAutoLoginChecked] = useState(false);
  const { showProgress, hideProgress } = useContext(FeedbackContext);
  const { authState, setAuthState } = useContext(AuthContext);
  const [alreadyFinished, setAlreadyFinished] = useState(false);

  useEffect(() => {
    if (autoLoginChecked) return;

    /** prevent getUserRole-call when Link is clicked with uuid */
    if (window.location.href.match(/\/test\/[0-9a-fA-F]{8}/)) {
      setAutoLoginChecked(true);
      return;
    }

    if (authState.loggedIn) {
      setAutoLoginChecked(true);
    } else {
      const token = getStoredAuthToken();
      if (token) {
        console.log("try auto login ueber storage");
        showProgress();
        getUserRole()
          .then((result) => {
            if (result.status === 400) {
              removeStoredAuthToken();
              setAlreadyFinished(true);
              setAutoLoginChecked(true);
            } else if (result.success && result.data) {
              setAuthState(
                buildAuthState(
                  true,
                  token,
                  result.data.role,
                  result.data.user,
                  result.data.settings
                )
              );
              if (result.data.role !== "candidate") setAutoLogout();
              setAutoLoginChecked(true);
              hideProgress();
            } else {
              removeStoredAuthToken();
              redirectAfterLoggedout();
            }
          })
          .catch((e) => {
            console.log(
              "%cuseAutoLogin.ts line:49 catcg",
              "color: #007acc;",
              e
            );
            hideProgress();
            removeStoredAuthToken();
            redirectAfterLoggedout();

            console.log("autologin failed ", e);
          });
      } else setAutoLoginChecked(true);
    }
  }, [
    autoLoginChecked,
    setAutoLoginChecked,
    authState,
    setAuthState,
    showProgress,
    hideProgress,
  ]);

  return { autoLoginChecked, alreadyFinished };
};
