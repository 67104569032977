import { mm_ss } from "../shared/helper/datetime";

interface TestTimerPropsI {
  time: number;
  duration?: number;
  direction?: string;
}
export const TestTimer = ({
  time,
  duration = 0,
  direction = "up",
}: TestTimerPropsI) => {
  if (direction === "up") {
    if (time < 0) return <></>;
    else return <span data-testid="testimer-up">{mm_ss(time)}</span>;
  }

  if (time < 0 || duration < 0 || time > duration)
    return <span data-testid="testimer-up">{mm_ss(0)}</span>;
  return <span data-testid="testimer-down">{mm_ss(duration - time)}</span>;
};
