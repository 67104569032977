import React, { useState, useContext, useCallback, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import { SearchField } from "../shared/forms/SearchField";

import {
  EnhancedTableHead,
  HeadCellT,
  stableSort,
  getComparator,
  OrderDirT,
  handleRequestSort,
  NonOptionalKeys,
  ComparatorT,
} from "../shared/table/EnhancedTable";
import { usePagination } from "../shared/hooks/usePagination";
import { UserChangedT, UserContext } from "../user/UserContext";
import { ErrorContext } from "../error/ErrorContext";
import {
  updateUserBulk,
  setPasswordUser,
  updateUser,
  userLogin,
  UserT,
} from "../user/api";

import SaveChanges from "../shared/list/SaveChanges";
import { AddCustomer } from "./AddCustomer";
import { FeedbackContext } from "../feedback/FeedbackContext";
import { FileProvider } from "../file/FileContext";
import { Customer } from "./Customer";
import { AuthContext, buildAuthState } from "../auth/AuthContext";
import { storeAuthToken } from "../auth/localStore";
import { setAutoLogout } from "../shared/helper/logout";
import Typography from "@mui/material/Typography";
import { TableContext } from "../shared/table/TableContext";
import { TableColumnSelect } from "../shared/table/TableColumnSelect";
import { useSearch } from "../shared/hooks/useSearch";
import { useShowSave } from "../shared/hooks/useShowSave";
import TestAccountSelect from "../profession/TestAccountSelect";

export const CustomerTable = React.memo(() => {
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const { userList, setUserList, allUserEmails, setAllUserEmails } =
    useContext(UserContext);
  const { changedUserList, setChangedUserList } = useContext(UserContext);
  const { setError } = useContext(ErrorContext);
  const { setAuthState } = useContext(AuthContext);

  const [orderDir, setOrderDir] = useState<OrderDirT>("desc");
  const [orderBy, setOrderBy] = useState<NonOptionalKeys<UserT>>("id");
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination("customer");
  const [showSave, setShowSave] = useShowSave();
  const [filter, setFilter] = useSearch("customersearch");
  const [selectedTestAcount, setSelectedTestAcount] = useState<string>("all");

  const {
    headCells,
    cellIsHidden,
  }: {
    headCells: HeadCellT<UserT>[];
    cellIsHidden: (i: NonOptionalKeys<UserT>) => boolean;
  } = useContext(TableContext);

  const onClickPassword = useCallback(
    (id: number) => {
      showProgress();
      setPasswordUser(id)
        .then((res) => {
          if (res.success) openSnackbar("success", "Passwort-Mail gesendet");
          else setError("User not found");
        })
        .catch((error) => {
          setError(error);
        });
    },
    [openSnackbar, setError, showProgress]
  );

  const onClickSetActive = useCallback(
    (
      active: boolean,
      userState: UserChangedT,
      setUserState: React.Dispatch<React.SetStateAction<UserChangedT>>
    ) => {
      showProgress();
      setUserState({ ...userState, active: active ? 1 : 0 }); // we don't use prevState here to avoid 2 versions in db and state
      updateUser({ ...userState, active: active ? 1 : 0 })
        .then(() => {
          openSnackbar("success", "Daten gespeichert");
          setUserList((p) =>
            p.map((e) => {
              if (e.id === userState.id)
                return { ...e, active: active ? 1 : 0 };
              return e;
            })
          );
        })
        .catch((error) => {
          // console.log("setActive catch", error);
          setError(error);
        });
    },
    [openSnackbar, setError, setUserList, showProgress]
  );

  const onClickSetTestAccount = useCallback(
    (
      testAccount: boolean,
      userState: UserChangedT,
      setUserState: React.Dispatch<React.SetStateAction<UserChangedT>>
    ) => {
      showProgress();
      setUserState({ ...userState, testAccount: testAccount }); // we don't use prevState here to avoid 2 versions in db and state
      updateUser({ ...userState, testAccount: testAccount })
        .then(() => {
          openSnackbar("success", "Daten gespeichert");
          setUserList((p) =>
            p.map((e) => {
              if (e.id === userState.id)
                return { ...e, testAccount: testAccount };
              return e;
            })
          );
        })
        .catch((error) => {
          // console.log("setActive catch", error);
          setError(error);
        });
    },
    [openSnackbar, setError, setUserList, showProgress]
  );

  const onClickUserLogin = useCallback(
    (userState: UserChangedT) => {
      console.log("userState", userState);
      showProgress();
      userLogin(userState.uuid)
        .then((result) => {
          if (result.success && result.data && result.data.user) {
            setAuthState(
              buildAuthState(
                true,
                result.data.token,
                "customer",
                result.data.user
              )
            );
            storeAuthToken(result.data.token);
            setAutoLogout();
            window.location.href = "/customer";
          } else {
            setError("login failed " + result.error);
          }
        })
        .catch((error) => {
          setError(error);
        });
    },
    [setAuthState, setError, showProgress]
  );

  /** ************************************************************************
   *
   *
   */
  const saveUsers = () => {
    showProgress();
    console.log(
      "%cCustomerTable.tsx line:171 changedUserList",
      "color: #007acc;",
      changedUserList
    );

    updateUserBulk(changedUserList)
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          setUserList((prevState) =>
            prevState.map((oldCat) => {
              const replCat = changedUserList.find((e) => e.id === oldCat.id);

              if (replCat) {
                return {
                  ...oldCat,
                  ...replCat,
                };
              }
              return oldCat;
            })
          );

          setChangedUserList([]);
          setShowSave(false);
        } else {
          if (result.errors) {
            console.log(
              "%csrc/components/customer/CustomerTable.tsx:176 result.errors",
              "color: #007acc;",
              result.errors
            );
            if (result.errors) {
              if (JSON.stringify(result.errors).indexOf("already") > 0) {
                setError("Diese E-Mail ist bereits vorhanden!");
              } else setError(JSON.stringify(result.errors));
            }
          }
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const comparator: ComparatorT<UserT> = getComparator(orderDir, orderBy);

  const filteredUserList = userList.filter(
    (e) =>
      ((e.firstname || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
        (e.lastname || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
        (e.email || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0) &&
      (selectedTestAcount === "all" ||
        e.testAccount === (selectedTestAcount === "1"))
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredUserList.length - page * rowsPerPage);

  return (
    <Typography variant="body1" component="span">
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <TestAccountSelect
            value={selectedTestAcount}
            setValue={setSelectedTestAcount}
            id="selected-type"
            showAll={true}
            size="small"
          />
        </Grid>
        <Grid item sx={{ marginRight: "auto" }}>
          {showSave && <SaveChanges onClick={saveUsers} />}
          <SearchField
            value={filter}
            setValue={setFilter}
            id="customersearch"
            handleChangePage={handleChangePage}
          />
        </Grid>
        <Grid item>
          <TableColumnSelect />
        </Grid>
        <Grid item>
          <AddCustomer setFilter={setFilter} />
        </Grid>
      </Grid>

      <TableContainer>
        <Table
          sx={{ minWidth: "80vw" }}
          className="customer-table"
          size="small"
        >
          <EnhancedTableHead
            headCells={headCells}
            orderDir={orderDir}
            orderBy={orderBy}
            onRequestSort={(event, property) =>
              handleRequestSort(
                event,
                property,
                orderDir,
                setOrderDir,
                orderBy,
                setOrderBy
              )
            }
            rowCount={filteredUserList.length}
          />
          <TableBody>
            {stableSort<UserT>(filteredUserList, comparator)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, index) => {
                return (
                  <FileProvider key={user.id}>
                    <Customer
                      key={user.id}
                      user={user}
                      setShowSave={setShowSave}
                      onClickPassword={onClickPassword}
                      onClickSetActive={onClickSetActive}
                      onClickSetTestAccount={onClickSetTestAccount}
                      setChangedUserList={setChangedUserList}
                      onClickUserLogin={onClickUserLogin}
                      allUserEmails={allUserEmails}
                      setAllUserEmails={setAllUserEmails}
                      setUserList={setUserList}
                      cellIsHidden={cellIsHidden}
                    />
                  </FileProvider>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 54 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUserList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={(event) =>
          handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)
        }
      />
    </Typography>
  );
});
