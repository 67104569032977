import { TransactionT } from "../../user/api";
import { useContext, useEffect, useState } from "react";

import { ProfessionContext } from "../../profession/ProfessionContext";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Modal } from "@mui/material";
import { TargetProvider } from "../../targets/TargetContext";
import { TargetLoader } from "../../targets/TargetLoader";
import { date_hr } from "../../shared/helper/datetime";
import { EnhancedTableHead, HeadCellT } from "../../shared/table/EnhancedTable";
import { updateProfessionSettingsBulk } from "../../profession/api";
import { debouncedSetUpdatedState } from "../../shared/helper/state";
import { EditableCell } from "../../shared/list/InsertCell";
import SaveChanges from "../../shared/list/SaveChanges";
import { ErrorContext } from "../../error/ErrorContext";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import { Tooltip } from "../../shared/forms/Tooltip";
import { CloseModalButton } from "../../shared/modal/CloseModalButton";
import { tooltip as targetTooltip } from "../../targets/Targets";
import { CustomerResultsModal } from "../../customer/CustomerResultsModal";
import { ShopOrderContext } from "../../order/ShopOrderContext";
import { calcPrice } from "../../order/shop";
import { CartItemT } from "../../order/api";
import { addToCart } from "../../order/cart";

interface TransactionsPropsI {
  transactionList: TransactionT[];
}

const shopUrl = import.meta.env.VITE_SHOPURL;

export const Transactions = ({ transactionList = [] }: TransactionsPropsI) => {
  const { professionList } = useContext(ProfessionContext);

  const [showTargets, setShowTargets] = useState(0);
  const [showSave, setShowSave] = useState(false);
  const [changedTransactionList, setChangedTransactionList] = useState<
    TransactionT[]
  >([]);

  const { articleList } = useContext(ShopOrderContext);

  const { setError } = useContext(ErrorContext);
  const { openSnackbar, showProgress } = useContext(FeedbackContext);
  const [showResults, setShowResults] = useState(0);

  const headCells: HeadCellT<TransactionT>[] = [
    {
      id: "id",
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdAt",
      disablePadding: true,
      label: "Datum",
    },
    {
      id: "professionId",
      disablePadding: true,
      label: "Sollprofil",
      tooltip: targetTooltip,
    },
    {
      id: "number",
      disablePadding: true,
      label: "Anzahl Tests",
    },
    {
      id: "numberUsed",
      disablePadding: true,
      label: "Tests vergeben",
    },
    {
      id: "numberDone",
      disablePadding: true,
      label: "Tests absolviert",
    },
    {
      id: "expiredAt",
      disablePadding: true,
      label: "Läuft bis",
      tooltip: (
        <>
          Geben Sie an, bis wann die Teilnehmer/innen diesen Test spätestens
          absolvieren sollen. Danach ist der Zugang zum Test gesperrt.
        </>
      ),
    },
    {
      id: "id2",
      label: "Zum Test",
      disablePadding: true,
    },
    {
      id: "id3",
      label: "Zur Auswertung",
      disablePadding: true,
    },
    {
      id: "id4",
      label: "",
      disablePadding: true,
    },
  ];

  const updateTarget = (professionId: number, target: number) => {
    transactionList.forEach((t) => {
      if (t.professionId === professionId) {
        t.target = target;
      }
    });
  };

  /** ************************************************************************
   *
   *
   */
  const saveTransactions = () => {
    showProgress();

    updateProfessionSettingsBulk(changedTransactionList)
      .then((result) => {
        if (result.success) {
          openSnackbar("success", "Daten gespeichert");
          transactionList.forEach((t) => {
            changedTransactionList.forEach((ct) => {
              if (t.professionId === ct.professionId) {
                t.expiredAt = ct.expiredAt;
              }
            });
          });
          setChangedTransactionList([]);
          setShowSave(false);
        } else setError(result.error);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const addItemToCart = (professionId: number) => {
    console.log(
      "%cTransactions.tsx line:148 articleList",
      "color: #007acc;",
      professionId,
      articleList
    );
    const article = articleList.find((a) => a.professionId === professionId);
    if (!article) {
      alert(
        "Dieser Artikel kann zur Zeit nicht bestelt werden. Bitte wenden Sie sich an unseren Kundenservice."
      );
      return;
    }
    console.log(
      "%cTransactions.tsx line:161 article",
      "color: #007acc;",
      JSON.stringify(article)
    );
    const pro = professionList.find((p) => p.id === professionId);
    if (article) {
      const cartItem: CartItemT = {
        articleId: article.id,
        artnr: article.artNo,
        type: pro && pro.type === "premium" ? "premium_online" : "online",
        numberLicenses: 1,
        numberBooks: 0,
        price: calcPrice("online", 1),
        numberSolutions: 0,
        branding: false,
        article,
        professionId,
      };
      addToCart(cartItem);
      window.location.href = "/customer/cart";
    }
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          {showSave && <SaveChanges onClick={saveTransactions} />}
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          sx={{ width: "100%" }}
          size="small"
          className="enhanced-table transactions"
        >
          <EnhancedTableHead
            headCells={headCells}
            rowCount={transactionList.length}
          />
          <TableBody className="enhanced-table-body">
            {transactionList &&
              transactionList.map((t) => {
                const pro = professionList.find((p) => t.professionId === p.id);
                const article = pro
                  ? articleList.find((a) => a.professionId === pro.id)
                  : null;

                return (
                  <TableRow
                    key={"transaction_" + t.id}
                    sx={{
                      "&:last-child td, &:last-child td": { border: 0 },
                    }}
                  >
                    <TableCell component="td" scope="row">
                      {pro && pro.name + " " + pro.testId}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {t.createdAt ? date_hr(t.createdAt) : ""}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {pro && (
                        <Tooltip title="Sollprofil bearbeiten">
                          <a onClick={() => setShowTargets(pro.id)}>
                            {t.target} %
                          </a>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {t.number}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {t.numberUsed}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {t.numberDone}
                    </TableCell>
                    {t &&
                      insertEditableCell(
                        t,
                        setShowSave,
                        setChangedTransactionList
                      )}
                    <TableCell component="td" scope="row">
                      {pro && (
                        <Link
                          to={
                            "/customer/testmodus/" +
                            pro.id +
                            "/" +
                            pro.name.replace(/\//g, "%2F")
                          }
                          className="link-icon"
                        >
                          <ArrowCircleRightOutlinedIcon
                            fontSize="small"
                            className="arrow-icon"
                          />
                        </Link>
                      )}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {pro && (
                        <span className="link-icon">
                          <ArrowCircleRightOutlinedIcon
                            fontSize="small"
                            className="arrow-icon"
                            onClick={() => {
                              setShowResults(pro.id);
                            }}
                          />
                        </span>
                      )}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {t.orderItemId && pro && article ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => addItemToCart(pro.id)}
                          sx={{
                            minWidth: 205,
                          }}
                        >
                          Nachbestellen
                        </Button>
                      ) : t.orderItemId && pro ? (
                        <Tooltip
                          title="Dieser Test wurde
                    überarbeitet und steht Ihnen unter einer neuen Artikelnummer in
                    unserem Shop zur Verfügung. Um die Vergleichbarkeit der
                    Testergebnisse zu gewährleisten, sollten verschiedene Auflagen
                    nicht parallel eingesetzt werden."
                        >
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              color: "rgba(0, 0, 0, 0.26)",
                              backgroundColor: "rgba(0, 0, 0, 0.12)",
                              minWidth: 205,
                            }}
                          >
                            Neuauflage vorhanden
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Dieser Test wurde Ihrem Konto manuell  durch unseren Kundenservice zugeordnet. Für Nachbestellungen nutzen Sie bitte unseren Shop unter www.degeba.de oder kontaktieren Sie unser Serviceteam.">
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              color: "rgba(0, 0, 0, 0.26)",
                              backgroundColor: "rgba(0, 0, 0, 0.12)",
                              minWidth: 205,
                            }}
                          >
                            Nachbestellen
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ pt: 1, ml: 1 }}>
        Alle weiteren verfügbaren Tests finden Sie in unserem Online-Shop
        auf&nbsp;
        <a href={shopUrl} target="_blank" rel="noreferrer">
          www.degeba.de.
          <br />
          <Button variant="contained" sx={{ mt: 1 }}>
            ZUM ONLINE-SHOP
          </Button>
        </a>
      </Box>
      {Boolean(showTargets) && (
        <Modal
          open={true}
          onClose={() => setShowTargets(0)}
          className="modal-backdrop"
        >
          <Box className="modal modal700">
            <TargetProvider>
              <TargetLoader
                professionId={showTargets}
                close={() => setShowTargets(0)}
                onUpdate={updateTarget}
              />
            </TargetProvider>
            <CloseModalButton close={() => setShowTargets(0)} />
          </Box>
        </Modal>
      )}
      {showResults > 0 && (
        <CustomerResultsModal
          setShowResults={setShowResults}
          professionId={showResults}
        />
      )}
    </>
  );
};

const insertEditableCell = (
  transaction: TransactionT,
  setShowSave: (b: boolean) => void,
  setChangedTransactionList: (c: TransactionT[]) => void
) => {
  const [transactionState, setTransactionState] = useState(transaction);

  useEffect(() => {
    if (transactionState !== transaction) {
      setShowSave(true);
      debouncedSetUpdatedState(transactionState, setChangedTransactionList);
    }
  }, [transactionState, setChangedTransactionList, setShowSave]);

  return (
    <EditableCell
      id="expiredAt"
      label="Läuft bis"
      type="date"
      stateObject={transactionState}
      setStateObject={setTransactionState}
      placeHolder="unbeschränkt"
    />
  );
};
