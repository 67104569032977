import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface WarnDialogPropsI {
  title: string;
  content: string;
  open: boolean;
  textOK?: string;
  handleOK: () => void;
}

export function WarnDialog(props: WarnDialogPropsI) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        data-testid="WarnDialog"
        className="Warn-dialog"
      >
        <DialogTitle id="responsive-dialog-title" className="title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="content">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => props.handleOK()}
            color="primary"
            autoFocus
            sx={{ fontSize: "12px" }}
          >
            {props.textOK ? props.textOK : "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
