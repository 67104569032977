import React, { ReactElement, useContext, useEffect, useState } from "react";
import { fetchToken } from "./api";

import { AuthContext, buildAuthState } from "./AuthContext";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../layout/Copyright";
import Alert from "@mui/material/Alert";
import { storeAuthToken } from "./localStore";
import { setAutoLogout } from "../shared/helper/logout";
import { fetchCSRFCookie } from "../shared/lib/api";
import { Helmet } from "react-helmet";

interface LoginPropsI {
  type: "admin" | "customer";
}

export const Login = ({ type }: LoginPropsI): ReactElement => {
  const [email, setEmail] = useState(
    type === "admin"
      ? import.meta.env.VITE_DEFAULT_ADMIN_USERNAME || ""
      : import.meta.env.VITE_DEFAULT_CUSTOMER_USERNAME || ""
  );
  const [password, setPassword] = useState(
    type === "admin"
      ? import.meta.env.VITE_DEFAULT_ADMIN_PASSWORD || ""
      : import.meta.env.VITE_DEFAULT_CUSTOMER_PASSWORD || ""
  );
  const [error, setError] = useState("");
  const [errorAjax, setErrorAjax] = useState(false);

  const { setAuthState } = useContext(AuthContext);

  useEffect(() => {
    fetchCSRFCookie()
      .then()
      .catch((e) => {
        console.error(e);
        setErrorAjax(true);
      });
  }, []);

  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    // console.log("email", email, "password", password);
    e.preventDefault();

    fetchToken({ email, password })
      .then((result) => {
        if (result.success && result.data && result.data.token) {
          console.log("result:", result);
          if (
            result.data.user &&
            result.data.user.role &&
            result.data.user.role !== type
          )
            setError("Dieser User darf auf diesen Bereich nicht zugreifen!");
          else {
            setAuthState(
              buildAuthState(
                true,
                result.data.token,
                result.data.user.role,
                result.data.user,
                result.data.settings
              )
            );
            storeAuthToken(result.data.token);
            localStorage.setItem("drawerState", "true"); // should always be open on login
            localStorage.removeItem("cart");
            setAutoLogout();
          }
        } else {
          //console.log("Login failed");
          setError("Login fehlgeschlagen. Error: " + result.error);
        }
      })
      .catch((e) => {
        console.log("Login failed error:", e);
        setError("Login fehlgeschlagen!");
      });
  };

  return (
    <>
      <Helmet>
        <title>
          degeba - {type === "customer" ? "Testverwaltung" : "Adminbereich"}
        </title>
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="login-box"
        >
          <Box className="logo degeba-logo"></Box>

          <Typography component="h1" variant="h5">
            {
              {
                admin: "Admin Area",
                customer: "Testverwaltung | Login",
                candidate: "Teilnehmer | Login",
              }[type]
            }
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-Mail"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              data-testid="passwordinput"
            />
            <Button
              type="submit"
              onClick={clickHandler}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>

            <Link component={RouterLink} to={"/" + type + "/forgot-password"}>
              Passwort vergessen?
            </Link>
            {errorAjax && (
              <Alert
                severity="error"
                sx={{ width: "100%", mt: 3 }}
                className="error-long"
              >
                <b>Leider haben wir ein technisches Problem festgestellt.</b>
                <br />
                <br />
                Beim Aufruf der degeba Testverwaltung werden Daten von unserer
                Seite „api.degeba.de“ nachgeladen. Anscheinend lässt Ihre
                individuelle Konfiguration dies nicht zu. Das kann folgende
                Ursachen haben:
                <br />
                <br />
                <ul>
                  <li>Firewall-Einstellungen</li>
                  <li>
                    Werbeblocker (&#8222;AdBlocker&#8220;) und ähnliche
                    Browser-Erweiterungen
                  </li>

                  <li>
                    Andere Sicherheitsbeschränkungen (z.B. durch
                    Antiviren-Software)
                  </li>
                </ul>
                <br />
                Bitte heben Sie die Einschränkungen für unsere Websites auf.
                Wenden Sie sich gegebenenfalls an Ihre IT-Abteilung. Genaue
                Fehlerbeschreibung: Blockieren des dynamischen Nachladens von
                Daten von der Subdomain &#8222;api.degeba.de&#8220; beim Aufruf
                der Seite &#8222;account.degeba.de&#8220; (degeba
                Testverwaltung).
              </Alert>
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
};
